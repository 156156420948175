(function () {
    'use strict';

    $(activate);

    function activate () {
        dataSearcherHandler();
    }

    function dataSearcherHandler () {
        var 
            $searchField = $('[data-search-field]')
            , $searchData = $('[data-search]')
            , $searchClear = $('[data-search-field-clear]')
            , $searchParent = $('[data-search-parent]')
        ;
        
        $.expr[':'].icontains = icontains;

        $searchField.on('submit', preventSubmit);
        $searchField.on('keyup', searchInData);
        $searchClear.on('click', clearSearchField);

        function preventSubmit (e) {
            e.preventDefault();
        }

        function searchInData () {
            if($searchField.val().length > 2) {
                window.scrollTo(0, $searchField.offset().top - 80);
                $searchParent.hide();
                $('[data-search]:icontains('+ $searchField.val() +')')
                    .parents('[data-search-parent]')
                    .show();
            } else {
                $searchParent.show();
            }
        }

        function clearSearchField () {
            $searchField.val('');
            $searchParent.show();
        }

        function icontains (a, i, m) {
            return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
        }
    }
}());