(function () {
    'use strict';

    $(document).ready(activate);

    function activate () {
        datepickerHandler();
    }

    function datepickerHandler () {
        var
            options = {
                    showMonthAfterYear: false
                    , changeMonth: true
                    , changeYear: true
                }
            , config = $.extend(options)
        ;

        setDatepickerRegion();
        setDatepickerFormatDate();

        $('.datepicker').datepicker(config);

        function setDatepickerRegion () {
            var
                regionOptions = {
                    closeText: 'Fechar'
                    , prevText: '&#x3c;Anterior'
                    , nextText: 'Pr&oacute;ximo&#x3e;'
                    , currentText: 'Hoje'
                    , monthNames: ['Janeiro', 'Fevereiro', 'Mar&ccedil;o', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
                    , monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
                    , dayNames: ['Domingo', 'Segunda-feira', 'Ter&ccedil;a-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado']
                    , dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                    , dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
                    , weekHeader: 'Sm'
                    , dateFormat: 'dd/mm/yy'
                    , firstDay: 0
                    , isRTL: false
                    , showMonthAfterYear: false
                    , yearSuffix: ''
                }
            ;

            $.datepicker.regional['pt-BR'] = regionOptions;
            $.datepicker.setDefaults($.datepicker.regional['pt-BR']);
        }

        function setDatepickerFormatDate () {
            var
                date = new Date(26, 1 - 1, 2007)
            ;

            $.datepicker.formatDate("dd/mm/yy",  date);
        }
    }
}());
