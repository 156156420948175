(function () {
    'use strict';

    $(activate);

    function activate () {
        $('#signupBtn').on('click', toggleSignupBox);
        $('#sucessBtn').on('click', closeSignupBox);
        $('#overlay').on('click', closeSignupBox);
    }

    function toggleSignupBox() {
        if ($('#overlay').hasClass('active') && $('#signup').hasClass('active')) {
            $('#signup').removeClass('active');
            $('#overlay').removeClass('active');
        } else {
            $('#signup').addClass('active');
            $('#overlay').addClass('active');
        }
    }
    function closeSignupBox() {
        if ($('#signup').hasClass('active')) {
            $('#overlay').removeClass('active');
            $('#signup').removeClass('active');
        }
    }
}());
