(function () {
var ctx = $("#babychart");
var data = {
    labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: [{
        label: 'PC acima do esperado para a idade',
        fill: false,
        backgroundColor: "rgba(255,0,0,1)",
        lineTension: 0.1,
        borderColor: "rgba(255,0,0,1)",
        data: [36, 38, 40, 41, 42, 43, 43.5, 44, 45, 45]
    },
        {
        label: 'PC adequado para a idade',
        fill: false,
        backgroundColor: "rgba(75,192,192,1)",
        lineTension: 0.1,
        borderColor: "rgba(75,192,192,1)",
        data: [34, 36, 38, 39, 40, 41, 41.5, 42, 43, 43]
    },
        {
        label: 'PC abaixo do esperado para a idade',
        fill: false,
        backgroundColor: "rgba(255,0,0,1)",
        lineTension: 0.1,
        borderColor: "rgba(255,0,0,1)",
        data: [32, 34, 36, 37, 38, 39, 39.5, 40, 41, 41]
        },
        {
        label: 'Criança Abaixo do Esperado',
        fill: false,
        backgroundColor: "rgba(0,0,0,1)",
        lineTension: 0.1,
        borderColor: "rgba(0,0,0,1)",
        data: [31, 33, 34, 35, 36, 38, 37.5, 38, 39, 39]
        }
    ]
};

if(ctx.length > 0){
    var myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: {
            responsive: true,
            tooltips: {
                mode: 'label'
            },
            scales: {
               yAxes: [{
                        type: "linear",
                        display: true,
                        id: "y-axis-1",
                        gridLines:{
                            display: false
                        },
                        labels: {
                            show:true,
                            
                        }
                    }, {
                        type: "linear",
                        display: true,
                        gridLines:{
                            display: true
                        },
                        labels: {
                            show:true,
                            
                        },
                    scaleLabel: {
                        display: true,
                        labelString: 'Perímetro Cefálico (cm)'
                    }
                }],
                xAxes: [{
                    ticks: {
                        maxTicksLimit: 5
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Idade (meses)'
                    }
                }]
            }
        }
    });
}

}());
